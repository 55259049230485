import React from 'react';

import { useField, useFormikContext } from 'formik';

import { InputProps } from '@/common/components/form/input';
import { InputPassword } from '@/common/components/form/input-password';
import { MaskedInput } from '@/common/components/form/masked-input';

export type MasksTypes =
  | 'phone'
  | 'cpf'
  | 'cnpj'
  | 'cpfCnpj'
  | 'cnj'
  | 'money'
  | 'postalCode'
  | 'creditCard'
  | 'expiryCard'
  | 'time';

export type FieldInputProps = InputProps & {
  enableSubmit?: boolean;
  mask?: MasksTypes;
};

const FieldInputPassword = React.memo(
  ({ enableSubmit, mask, ...props }: FieldInputProps) => {
    const [field, fieldMeta] = useField(props);
    const { submitForm } = useFormikContext();

    const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (props.onKeyUp) props.onKeyUp(e);
      if (e.key === 'Enter' && enableSubmit) submitForm().then();
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      if (props.onBlur) props.onBlur(e);
      field.onBlur(e);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (props.onChange) {
        props.onChange(e);
      }
      field.onChange(e);
    };

    if (mask) {
      return (
        <MaskedInput
          {...props}
          {...field}
          onKeyUp={handleKeyUp}
          onBlur={handleBlur}
          onChange={handleChange}
          mask={mask}
          meta={props.meta || fieldMeta}
        />
      );
    }

    return (
      <InputPassword
        {...props}
        {...field}
        onKeyUp={handleKeyUp}
        onBlur={handleBlur}
        onChange={handleChange}
        meta={props.meta || fieldMeta}
      />
    );
  }
);

FieldInputPassword.displayName = 'FieldInputPassword';

export { FieldInputPassword };
