import { darken, transparentize } from 'polished';
import styled, { css } from 'styled-components';
import { ButtonProps } from '@/common/components/button';
import { ColorMode, SizeMode } from '@/common/styles/theme';

const Wrapper = styled.button<
  ButtonProps & { mode: ColorMode; buttonSize: SizeMode }
>`
  ${({ theme, mode, outline, buttonSize, minimal, loading }) => css`
    border-radius: ${theme.border.radius};
    padding: ${buttonSize === 'large' && '1rem 2rem'}
      ${buttonSize === 'medium' && '0.8rem 1.6rem'}
      ${buttonSize === 'small' && '0.5rem 1rem'};
    border: 0;
    outline: 0;
    text-decoration: none;
    cursor: pointer;
    justify-content: center;
    color: ${outline || minimal ? theme.colors[mode] : theme.colors.white};
    background: ${outline || minimal ? 'transparent' : theme.colors[mode]};
    border: ${outline && `solid 1px ${theme.colors[mode]}`};
    font: ${!outline && !minimal && theme.font.xbold}
      ${theme.font.sizes[buttonSize]} ${theme.font.family};
    ${!(minimal || outline) && 'text-transform: uppercase'};
    display: flex;
    align-items: center;

    svg {
      margin-right: ${theme.spacings.xxsmall};
      margin-left: ${theme.spacings.xxsmall};
    }

    @keyframes rotate {
      from {
        transform: rotate(0);
      }
      to {
        transform: rotate(360deg);
      }
    }

    ${loading &&
    css`
      &::after {
        content: '';
        width: 12px;
        height: 12px;
        border: solid 3px ${outline || minimal ? theme.colors[mode] : '#fff'};
        margin-left: 5px;
        border-radius: 50%;
        border-top-color: ${outline || minimal
          ? transparentize(0.8, theme.colors[mode])
          : 'rgba(255, 255, 255, .2)'};
        animation: rotate infinite linear 1s;
      }
    `}

    &:hover {
      background: ${outline || minimal
        ? transparentize(0.92, theme.colors[mode])
        : darken(0.05, theme.colors[mode])};
    }
  `}
`;

export { Wrapper };
