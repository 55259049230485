import React from 'react';
import { ContentFooter } from '@/common/components/modal/styles';

type ModalFooterProps = {
  children: React.ReactNode | React.ReactNode[];
};

const ModalFooter = ({ children }: ModalFooterProps) => {
  return <ContentFooter>{children}</ContentFooter>;
};

export { ModalFooter };
