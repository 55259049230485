import React from 'react';
import styled, { css } from 'styled-components';

export type HeadingProps = {
  small?: boolean;
  noMargin?: boolean;
} & React.HtmlHTMLAttributes<HTMLHeadingElement>;

const Heading = styled.h1<HeadingProps>`
  ${({ theme, small, noMargin }) => css`
    font: ${theme.font.xbold}
      ${small ? theme.font.sizes.medium : theme.font.sizes.xxlarge}
      ${theme.font.family};
    color: ${theme.colors.company};
    margin-bottom: ${noMargin
      ? theme.spacings.xxsmall
      : small
      ? theme.spacings.small
      : theme.spacings.large};
  `}
`;

export { Heading };
