import React from 'react';

import { ContentMain } from '@/common/components/modal/styles';

type ModalFooterProps = {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
};

const ModalContent = ({ children, className }: ModalFooterProps) => {
  return <ContentMain className={className}>{children}</ContentMain>;
};

export { ModalContent };
