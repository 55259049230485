import { lighten } from 'polished';
import styled, { css } from 'styled-components';

import { InputProps, TextInputProps } from '@/common/components/form/input';

const Label = styled.label`
  ${({ theme }) => css`
    display: block;
    color: ${theme.colors.black};
    font: ${theme.font.bold} ${theme.font.sizes.xsmall} ${theme.font.family};
    margin-bottom: ${theme.spacings.xxsmall};
  `}
`;

const Wrapper = styled.div<Omit<InputProps, 'name' | 'inputRef'>>`
  ${({ theme, required, noSpace }) => css`
    position: relative;
    ${!noSpace && `margin-bottom: ${theme.spacings.small};`}
    ${required &&
    css`
      ${Label} {
        &::after {
          content: '∗';
          color: ${theme.colors.danger};
          margin-left: 5px;
        }
      }
    `}
  `}
`;

const TextInput = styled.input<TextInputProps>`
  ${({ theme, valid }) => css`
    align-items: center;
    position: relative;
    background: ${theme.colors.lightGray};
    border-radius: ${theme.border.radius};
    padding: ${theme.spacings.xsmall};
    outline: 1px solid ${theme.colors.borders};
    width: 100%;
    border: 0;
    color: ${theme.colors.black};
    font: ${theme.font.sizes.small} ${theme.font.family};

    &:focus {
      outline: 1px solid ${theme.colors.primary};
    }

    &[type='date'] {
      position: relative;
    }

    ::-webkit-calendar-picker-indicator {
      position: absolute;
      right: 16px;
      padding: 8px;
      border-radius: 50%;
      background-color: ${lighten(0.42, theme.colors.primary)};
      cursor: pointer;
      color: yellow;
    }

    ${!valid &&
    css`
      outline: 1px solid ${theme.colors.danger};
    `}
  `}
`;

const ErrorMessage = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.danger};
    font-size: ${theme.font.sizes.xsmall};
  `}
`;

const Icon = styled.span`
  position: absolute;
  top: 38px;
  right: 16px;
  z-index: 1000;
  cursor: pointer;
`;

export { Wrapper, Label, TextInput, ErrorMessage, Icon };
