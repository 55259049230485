import styled, { css } from 'styled-components';

const Root = styled.div<{ isOpen: boolean }>`
  ${({ isOpen }) => css`
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1010;
    display: ${isOpen ? 'flex' : 'none'};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  `}
`;

const Wrapper = styled.div`
  width: 700px;
  max-width: 80vw;
`;

const ContentTitle = styled.div`
  ${css`
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 2.4rem;
  `}
`;

const ContentMain = styled.div`
  ${css`
    position: relative;
    padding: 0 2.4rem;
    margin-bottom: 2.4rem;
  `}
`;

const ContentFooter = styled.div`
  ${({ theme }) => css`
    display: flex;
    position: relative;
    justify-content: space-between;
    padding: 2.4rem 2.4rem 0;
    margin-top: ${theme.spacings.large};
    border-top: 1px solid ${theme.colors.borders};
  `}
`;

export { Root, Wrapper, ContentTitle, ContentFooter, ContentMain };
