import React from 'react';
import { ColorMode, SizeMode } from '@/common/styles/theme';
import { Wrapper } from '@/common/components/button/styles';

export type ButtonProps = {
  children?: React.ReactNode;
  buttonSize?: SizeMode;
  outline?: boolean;
  minimal?: boolean;
  loading?: boolean;
  mode?: ColorMode;
  as?: React.ElementType;
  target?: string;
  href?: any;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button: React.FC<ButtonProps> = ({
  children,
  buttonSize = 'medium',
  outline = false,
  mode = 'primary',
  minimal = false,
  loading = false,
  ...props
}) => (
  <Wrapper
    minimal={minimal}
    loading={loading}
    buttonSize={buttonSize}
    outline={outline}
    mode={mode}
    {...props}>
    {children}
  </Wrapper>
);

export { Button };
