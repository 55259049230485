import React from 'react';
import { Root, Wrapper } from '@/common/components/modal/styles';
import { Card } from '@/common/components/card';

type ModalProps = {
  isOpen: boolean;
  children: React.ReactNode | React.ReactNode[];
};

const Modal = ({ isOpen, children }: ModalProps) => {
  return (
    <Root isOpen={isOpen}>
      <Wrapper>
        <Card>{children}</Card>
      </Wrapper>
    </Root>
  );
};

export { Modal };
