import React from 'react';
import { ContentTitle } from '@/common/components/modal/styles';
import { Button } from '@/common/components/button';
import { FiX } from 'react-icons/fi';

type ModalTitleProps = {
  children?: React.ReactNode | React.ReactNode[];
  onClose?: () => void;
};

const ModalTitle = ({ children, onClose }: ModalTitleProps) => {
  return (
    <ContentTitle>
      {children}
      {onClose && (
        <Button minimal onClick={onClose}>
          <FiX size={24} />
        </Button>
      )}
    </ContentTitle>
  );
};

export { ModalTitle };
