import React, { ChangeEvent, InputHTMLAttributes } from 'react';

import { FieldMetaProps } from 'formik/dist/types';

import {
  Wrapper,
  Label,
  TextInput,
  ErrorMessage,
  Icon,
} from '@/common/components/form/input-password/styles';
import { usePasswordToggle } from '@/common/hooks/use-password-toggle';

export type InputProps = {
  label?: string;
  labelFor?: string;
  name: string;
  inputRef?: any;
  meta?: FieldMetaProps<any>;
  noSpace?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

export type TextInputProps = {
  valid: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

const InputPassword = ({
  label,
  labelFor = '',
  required,
  inputRef,
  meta,
  onChange,
  value,
  className,
  noSpace,
  ...props
}: InputProps) => {
  const [PasswordInputType, ToggleIcon] = usePasswordToggle();
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      if (props.type === 'date') {
        onChange({
          ...event,
          target: {
            ...event.target,
            name: props.name,
            value:
              event.target.value?.split('-')?.reverse().join('/') ||
              event.target.value,
          },
        });
      } else {
        onChange(event);
      }
    }
  };

  const getValue = () => {
    if (props.type === 'date') {
      return value?.toString()?.split('/').reverse().join('-');
    }
    return value;
  };

  return (
    <Wrapper required={required} noSpace={noSpace} className={className}>
      {!!label && <Label htmlFor={labelFor}>{label}</Label>}
      <TextInput
        valid={!(meta?.touched && meta?.error)}
        ref={inputRef}
        type={PasswordInputType as string}
        {...props}
        value={getValue()}
        onChange={handleOnChange}
      />
      <Icon>{ToggleIcon}</Icon>
      {meta?.touched && meta?.error && (
        <ErrorMessage>{meta.error}</ErrorMessage>
      )}
    </Wrapper>
  );
};

export { InputPassword };
