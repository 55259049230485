import { useState } from 'react';

import { FiEye, FiEyeOff } from 'react-icons/fi';

const usePasswordToggle = () => {
  const [visible, setVisibility] = useState(false);
  const changeVisibility = () => setVisibility(!visible);

  const Icon = visible ? (
    <FiEye onClick={changeVisibility} />
  ) : (
    <FiEyeOff onClick={changeVisibility} />
  );
  const InputType = visible ? 'text' : 'password';

  return [InputType, Icon];
};

export { usePasswordToggle };
